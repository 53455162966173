import React from "react";
import "./slider.css"
import {Swiper , SwiperSlide} from "swiper/react"
import { Navigation, Pagination, } from 'swiper';
import { SliderProducts } from "../../data/products";
//import swiper styles
import "swiper/css"
import "swiper/css/bundle"

const Slider = () =>{
    return(
        <>
        <div className="s-container">
        <Swiper
        breakpoints={{
            640:{
                slidesPerView:3,
            },
            0:{
                slidesPerView:1
            }
        }}
        modules={[Pagination , Navigation]}
        className = "myswiper"
        loopFillGroupWithBlank = {true}
        navigation = {true}
        slidesPerView={3}
        spaceBetween={40}
        slidesPerGroup={1}
        loop = {true}
        >
            
                {
                    SliderProducts.map((slide , i)=>{
                      return(
                        <>
                        <SwiperSlide>
                            <div className="l-sidee">
                                <div className="name">
                                    <span>{slide.name}</span>
                                    <span>{slide.detail}</span>
                                </div>
                                <span>{slide.price}$</span>
                                <div>Shop More</div>
                            </div>
                            <img src={slide.img} alt="" className="img-p"/>

                        </SwiperSlide>
                        </>

                    )})
                }


           
  

        </Swiper>
        </div>
        </>
    )
}

export default Slider;