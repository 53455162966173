import React from 'react'
import "./Product.css"
import Plane from "../../assets/plane.png"
import {ProductsData} from "../../data/products"
import { useState } from 'react'

const Prodeucts = () => {

  const[MenuProducts , setMenuProducts] = useState(ProductsData)

  const filter = (type) =>{
    setMenuProducts(ProductsData.filter((product)=> product.type===type))
  }
  return (
    <>
   <div className="containerr">
    <img src={Plane} alt="" />
    <h1>Our Featur Products</h1>
    <div className="productss">
      <ul className="men">
        <li onClick={()=>setMenuProducts(ProductsData)}>All</li>
        <li onClick={()=>filter("skin care")}>Skin Care</li>
        <li onClick={()=>filter("conditioner")}>Conditoners</li>
        <li onClick={()=>filter("foundation")}>Foundation</li>
      </ul>

      <div className="list">
        {
          MenuProducts.map((product , i)=>{
            return(
              <>
              <div className='pro'>
                <div className="l-sidee">
                                <div className="name">
                                    <span>{product.name}</span>
                                    <span>{product.detail}</span>
                                </div>
                                <span>{product.price}$</span>
                                <div>Shop More</div>
                  </div>
                            <img src={product.img} alt="" className="img-p"/>

                            </div>

              </>
            )
          })
        }
      </div>
    </div>
   </div>
    </>
  )
}

export default Prodeucts