import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Slider from './components/slider/slider';
import Virtuel from './components/virtual/Virtual';
import Prodeucts from './components/Prodeucts/Prodeucts';
import Test from './components/Testemonials/Test';
import Footer from './components/footer/Footer';

function App() {
  return (
    <>
   <Header />
   <Hero/>
   <Slider />
   <Virtuel />
   <Prodeucts />
   <Test />
   <Footer/>
   </>
  );
}

export default App;
