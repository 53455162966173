import React, { useState } from 'react'
import "./Header.css"
import logo from "../../assets/logo.png"
import {BsCartDashFill} from "react-icons/bs"
import {GoThreeBars} from "react-icons/go"





const Header = () => {
  const[show , setshow]= useState(true);
  const toggleItem=()=>{
    setshow((show)=>!show)
  }
  return (
    <>
    <div className="containere">
        <div className="logo">
            <img src= {logo} alt="" />
            <span className="txt">Amzon</span>
        </div>


      <div className="right">
        <div className="bar" onClick={toggleItem}>
          <GoThreeBars/>
        </div>
       
            <ul className='menu' style={{display : show? "inherit":"none"}}>
                <li>Collection</li>
                <li>Brands</li>
                <li>Sale</li>
                <li>ENG</li>
                <li>New</li>
            </ul>
        

        <input type="text" className='search' placeholder='search' />
      <BsCartDashFill className='ic'/>
        </div>
    </div>
    
    
    </>
   
  )
}

export default Header