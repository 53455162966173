import React from "react";

import "./virtual.css"
import Shad from "../../assets/shade.png"
import Before from "../../assets/before.png"
import After from "../../assets/after.png"
import ReactCompareImage from "react-compare-image";

const  Virtuel =() =>{
    return(
        <>
        <div className="virtual">
            <div className="lft">
                <span>Virtual Try-on</span>
                <span>Never Buy the wrong shade again</span>
                <span>Try One!</span>
                <img src={Shad} alt="" />
            </div>
            <div className="rgt">
                <div className="wra">
                < ReactCompareImage leftImage={Before} rightImage={After} />
                </div>
              
            
            </div>
        </div>
        </>
    )

}
export default Virtuel