import React from 'react'
import "./Footer.css"
import Logo from "../../assets/logo.png"
import {GrLocation} from "react-icons/gr"
import {MdOutlineContactPage} from "react-icons/md"
import {MdAccountCircle} from "react-icons/md"
 import {FcAbout} from "react-icons/fc"
 import {FcPrivacy} from "react-icons/fc"
// import {GrLocation} from "react-icons/gr"

const Footer = () => {
  return (
    <>
    <div className="cFooterWrapper">
        <hr/>
        <div className="cFooter">
            <div className="Logo">
                <img src={Logo} alt="" />
                <span>Amazon</span>
            </div>

            <div className="block">
               
                    <h1>Contact Us</h1>
                    <div className="detail">
                    <span className="pngLine">
                        <GrLocation className='icon'/>

                    </span>
                    <span>Hose numer 1146 , chaklala</span>
                  
                </div>
                      <div className="detail">
                    <span className="pngLine">
                        <MdOutlineContactPage className='icon'/>

                    </span>
                    <span>0302-5878708</span>
                  
                </div>
            </div>
            <div className="block">
               
               <h1>Accout</h1>
               <div className="detail">
               <span className="pngLine">
                 <MdAccountCircle></MdAccountCircle>

               </span>
               <span>Sign in</span>
           </div>
       </div>





       <div className="block">
               
               <h1>Company</h1>
               <div className="detail">
               <span className="pngLine">
                   <FcAbout></FcAbout>

               </span>
               <span>About Us</span>
               
           </div>
           
       </div>



       <div className="block">
               
               <h1>Resources</h1>
               <div className="detail">
               <span className="pngLine">
                 <FcPrivacy></FcPrivacy>

               </span>
               <span>Security and privacy</span>
           </div>
       </div>
      
        </div>
        <div className="copywrite">
        <span>Copy Write @2022 by Amazone</span>
        <span>All rights are reserved</span>
       </div>
    </div>
    </>
  )
}

export default Footer